import Swiper, { Mousewheel } from "swiper";
import "swiper/swiper-bundle.min.css";

class CategoriesBar {
  constructor($el) {
    this.$el = $el;
    this.currentDevice = this.matchMediaQueries();

    const swiper = new Swiper(".categories__container", {
      direction: "horizontal",
      mousewheel: {
        mousewheelSensitivity: 0,
        mousewheelReleaseOnEdges: true,
        forceToAxis: true,
      },
      slidesPerView: "auto",
      freeMode: true,
      followFinger: true,
      spaceBetween: this.currentDevice.isPhone ? 24 : 32,
      modules: [Mousewheel],
    });

    window.addEventListener("resize", this.handleResize);
  }

  matchMediaQueries = (width = window.innerWidth) => {
    return {
      isPhone: width <= 767,
      isTablet: width <= 1023,
      isLaptop: width <= 1279,
      isDesktop: width > 1279,
    };
  }

  handleResize = () => {
    this.currentDevice = this.matchMediaQueries();
  }
}

document.addEventListener("DOMContentLoaded", function () {
  new CategoriesBar($(".categories"));
});
