import $ from "jquery";

const LS_POPUP_KEY = "kingkongPopupClosed";

class Popup {
  constructor($el) {
    this.$el = $el;
    this.wasClosed = localStorage.getItem(LS_POPUP_KEY);

    const closeBtn = this.$el.find('.popup__close')
    const popupInstallButton = this.$el.find('.popup__install-button')
    const popupDontNeedButton = this.$el.find('.popup__dont-need-button')
    const iconBtn = this.$el.find('.popup__icon')

    closeBtn.click(this.closePopup.bind(this));
    popupDontNeedButton.click(this.closePopup.bind(this));
    popupInstallButton.click(this.intallApp.bind(this))
    iconBtn.click(this.clickStoreButton.bind(this));

    if (window.innerWidth < 768 && !this.wasClosed) {
      setTimeout(() => {
        this.openPopup()
      }, 1500)
    }
  }

  openPopup() {
    ym(72781045,'reachGoal','openAppPopup')
    this.$el.toggleClass("is-visible");
  }

  closePopup() {
    localStorage.setItem(LS_POPUP_KEY, true);
    this.$el.toggleClass("is-visible");
    ym(72781045,'reachGoal','closeAppPopup');
  }

  intallApp() {
    console.log(2222)
    ym(72781045,'reachGoal','clickInstallButton');
    this.closePopup();
  }

  clickStoreButton() {
    ym(72781045,'reachGoal','clickStoreButton');
    this.closePopup();
  }
}

document.addEventListener("DOMContentLoaded", function () {
  new Popup($(".popup"));
});
