import $ from "jquery";
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";

class Topbar {
  constructor($el) {
    this.$el = $el;

    this.initMobileMenu();
  }

  initMobileMenu() {
    this.isMenuVisible = false;
    this.$menu = this.$el.find(".topbar__menu-mobile");
    this.$hamburger = this.$el.find(".topbar__hamburger");
    this.$hamburger.on("click", this.toggleMobileMenu.bind(this));
    $(window).on("resize", this.handleResize.bind(this));
  }

  handleResize() {
    if (this.isMenuVisible && window.innerWidth > 767) this.toggleMobileMenu();
  }

  toggleMobileMenu() {
    this.isMenuVisible = !this.isMenuVisible;
    this.$menu.toggleClass("is-visible", this.isMenuVisible);
    this.$hamburger.toggleClass("is-menu-visible", this.isMenuVisible);

    const updateBodyScroll = this.isMenuVisible
      ? disableBodyScroll
      : enableBodyScroll;
    updateBodyScroll(this.$menu.get(0));
  }
}

document.addEventListener("DOMContentLoaded", function () {
  new Topbar($(".topbar"));
});
